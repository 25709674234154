






















































import { Component, Emit, Prop, Vue } from 'nuxt-property-decorator'
import SupportAttention from '../SupportAttention/index.vue'
import { IConnectCompany } from '~/Interfaces'
import { IConnectCompanySettingCompany } from '~/Interfaces/connectCompany/IConnectCompanySettingCompany'
import ConnectV2Input from '~/components/common/components/v2/ConnectV2Input.vue'
import ConnectV2InputLabel from '~/components/common/components/v2/ConnectV2InputLabel.vue'
import ConnectV2Checkbox from '~/components/common/components/v2/ConnectV2Checkbox.vue'

@Component({
  components: {
    ConnectV2Checkbox,
    ConnectV2InputLabel,
    ConnectV2Input,
    SupportAttention,
  },
})
export default class InvoiceForm extends Vue {
  @Prop()
  private connectCompany!: IConnectCompany | IConnectCompanySettingCompany

  @Prop()
  private hideIssuing!: boolean

  private errorText: string = ''
  private errorCheck: boolean = false
  private disabled: boolean = false

  mounted() {
    this.disabled = !!this.connectCompany.isIssuingInvoiceNumber
  }

  get hint() {
    return this.hideIssuing
      ? '税務署より適格請求書発行事業者へ発行される「T」+13桁の数字です'
      : '税務署より適格請求書発行事業者へ発行される「T」+13桁の数字です（※プロフィールには記載されません）'
  }

  get inputDisabled() {
    if (this.hideIssuing) return false

    return this.disabled
  }

  validator() {
    const invoiceNumber = this.connectCompany.invoiceNumber
    const isIssuingInvoiceNumber = this.connectCompany.isIssuingInvoiceNumber
    if (!invoiceNumber) {
      if (isIssuingInvoiceNumber) {
        return true
      }

      this.errorCheck = true
      this.errorText = '必ず入力してください'
      return false
    }

    const regex = /^T\d{13}$/
    const isValid = invoiceNumber?.length === 14 && regex.test(invoiceNumber)
    if (!isValid) {
      this.errorText = '「T」+13桁の数字で入力してください'
    }

    return isValid
  }

  private onChange(event: Event) {
    const value = (event.target as HTMLInputElement).value
    if (!value) {
      this.errorText = '必ず入力してください'
    }

    const regex = /^T\d{13}$/
    const isValid = value.length === 14 && regex.test(value)
    if (!isValid) {
      this.errorText = '「T」+13桁の数字で入力してください'
    } else {
      this.errorText = ''
    }

    this.connectCompany.invoiceNumber = value
    this.update(this.connectCompany)
  }

  private onCheck(event: Event) {
    this.resetError()

    const checked = (event.target as HTMLInputElement).checked
    this.disabled = checked

    if (checked) {
      this.connectCompany.invoiceNumber = ''
    }

    this.connectCompany.isIssuingInvoiceNumber = checked
    this.update(this.connectCompany)
  }

  private resetError() {
    this.errorText = ''
    this.errorCheck = false
  }

  @Emit('update')
  private update(
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    connectCompany: IConnectCompany | IConnectCompanySettingCompany
  ) {}
}
