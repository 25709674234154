



































































import { Component, Emit, Prop, Vue } from 'nuxt-property-decorator'
import { IAttachment, IConnectProposal } from '~/Interfaces'
import ProposalDetailContent from '~/components/Proposal/detail/content/proposal-detail-content.vue'
import {
  ClientConnectProposalDetailsStore,
  CurrentConnectProposalStore,
} from '~/utils/store-accessor'
import IConnectProposalDetail from '~/Interfaces/ConnectProposalDetail/IConnectProposalDetail'
import ClientProposalDocumentListModal from '~/components/Client/ClientProposal/ClientProposalDetail/components/ClientProposalDocumentListModal/index.vue'
import Modal from '~/components/Modal.vue'
import {
  DegreeHelpModal,
  SupportSystemHelpModal,
  PartnerProfile,
} from '~/components/common/profile/partner'
import ClientProposalDetailProposal from '~/components/Client/ClientProposal/ClientProposalDetailVersion2/ClientProposalDetailProposal.vue'
import ClientProposalDetailTitle from '~/components/Client/ClientProposal/ClientProposalDetail/components/ClientProposalDetailTitle/index.vue'
import ConnectAttachmentThumbnail from '~/components/common/components/ConnectAttachmentThumbnail.vue'
import ProposalConfirmProposalDocumentAgreementModal from '~/components/Proposal/proposal-confirm-proposal-document-agreement-modal.vue'

@Component({
  components: {
    ConnectAttachmentThumbnail,
    ClientProposalDetailTitle,
    ClientProposalDetailProposal,
    ClientProposalDocumentListModal,
    ProposalConfirmProposalDocumentAgreementModal,
    DegreeHelpModal,
    Modal,
    PartnerProfile,
    ProposalDetailContent,
    SupportSystemHelpModal,
  },
})
export default class ClientProposalDetailContent extends Vue {
  @Prop()
  private connectProposal!: IConnectProposal

  private proposalDocumentList: Array<any> | null = null

  private isProposalDocumentModalOpen: boolean = false

  private isProposalDocumentAgreementModalOpen: boolean = false

  private onProposalDocumentAgreementModalOk = () => {}

  async mounted() {
    await this.fetchProposalDocumentList()
  }

  get isVisibleProfile(): boolean {
    return this.$services.user.abTestPatternName === 'a'
  }

  get connectProposalDetail(): IConnectProposalDetail | undefined {
    return ClientConnectProposalDetailsStore.find(
      this.connectProposal.connectProposalHashId
    )
  }

  get connectProposalDetailPrioritiesMaster(): string[] {
    return ClientConnectProposalDetailsStore.getPrioritiesMaster
  }

  get connectProposalStatus(): string {
    return this.connectProposal.connectProposalStatus as any
  }

  get companyUrl(): string {
    return `/client/profile/connect_company/${this.connectProposal.connectProposalHashId}`
  }

  get isVisibleProposalStatus(): boolean {
    return [
      'waiting',
      'negotiating',
      'pending',
      'rejected',
      'rejected_not_correspond',
    ].includes(this.connectProposalStatus)
  }

  get isVisibleProposalDocument(): boolean {
    return ['negotiating', 'accepted'].includes(this.connectProposalStatus)
  }

  get hasProposalDocument(): boolean {
    return (this.isVisibleProposalDocument &&
      this.proposalDocumentList &&
      this.proposalDocumentList.length > 0) as boolean
  }

  private isSelected(proposalStatus: string) {
    if (proposalStatus === 'rejected') {
      return ['rejected', 'rejected_not_correspond'].includes(
        this.connectProposalStatus
      )
    }
    return this.connectProposalStatus === proposalStatus
  }

  private onChange(proposalStatus: string) {
    this.updateStatus(this.connectProposal, proposalStatus)
  }

  @Emit('updateStatus')
  private updateStatus(
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    connectProposal: IConnectProposal,
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    proposalStatus: string
  ) {}

  private async fetchProposalDocumentList(): Promise<void> {
    this.proposalDocumentList = await this.$services.message.getProposalDocumentList(
      this.connectProposal.connectProposalHashId
    )
  }

  private async openProposalDocument(attachment: IAttachment) {
    const proposal = CurrentConnectProposalStore.getCurrentConnectProposal
    if (!proposal) {
      console.error('proposal is not found')
      return
    }
    if (!proposal.isAgreedProposalDocumentAgreement) {
      this.onProposalDocumentAgreementModalOk = () => {
        this.openProposalDocument(attachment)
      }
      this.openProposalDocumentAgreementModal()
      return
    }
    const res = await this.$services.message.downloadAttachmentByUrl(
      attachment.threadDetailAttachment as string
    )
    const blob = await res.data
    const newBlob = new Blob([blob], { type: 'application/pdf' })
    const objUrl = window.URL.createObjectURL(newBlob)
    const objectLink = document.createElement('a')
    objectLink.href = objUrl
    objectLink.target = '_blank'
    objectLink.click()
  }

  private openProposalDocumentModal() {
    if (!this.hasProposalDocument) {
      return
    }
    const proposal = CurrentConnectProposalStore.getCurrentConnectProposal
    if (!proposal) {
      console.error('proposal is not found')
      return
    }
    if (!proposal.isAgreedProposalDocumentAgreement) {
      this.onProposalDocumentAgreementModalOk = this.openProposalDocumentModal
      this.openProposalDocumentAgreementModal()
      return
    }
    this.isProposalDocumentModalOpen = true
  }

  private closeProposalDocumentModal() {
    this.isProposalDocumentModalOpen = false
  }

  private openProposalDocumentAgreementModal() {
    this.isProposalDocumentAgreementModalOpen = true
  }

  private closeProposalDocumentAgreementModal() {
    this.isProposalDocumentAgreementModalOpen = false
  }
}
