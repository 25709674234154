



















































import { Component, Emit, Prop, Vue } from 'nuxt-property-decorator'
import IProposalDocumentGroups from '~/Interfaces/Message/IProposalDocumentGroups'
import FormModal from '~/components/common/modal/form-modal.vue'
import ConnectModal from '~/components/common/components/ConnectModal.vue'

@Component({
  components: { ConnectModal, FormModal },
})
export default class ClientProposalDocumentListModal extends Vue {
  @Prop()
  proposalDocumentList!: IProposalDocumentGroups

  mounted() {}

  private async openProposalDocument(url: string) {
    const res = await this.$services.message.downloadAttachmentByUrl(url)
    const blob = await res.data
    const newBlob = new Blob([blob], { type: 'application/pdf' })
    const objUrl = window.URL.createObjectURL(newBlob)
    const objectLink = document.createElement('a')
    objectLink.href = objUrl
    objectLink.target = '_blank'
    objectLink.click()
  }

  @Emit('closeModal')
  private closeModal() {}
}
