































import { Component, Prop, Vue } from 'nuxt-property-decorator'
import { IAttachment } from '~/Interfaces'
import ConnectAttachmentThumbnail from '~/components/common/components/ConnectAttachmentThumbnail.vue'
import { CurrentConnectProposalStore } from '~/utils/store-accessor'
import ProposalConfirmProposalDocumentAgreementModal from '~/components/Proposal/proposal-confirm-proposal-document-agreement-modal.vue'

@Component({
  components: {
    ProposalConfirmProposalDocumentAgreementModal,
    ConnectAttachmentThumbnail,
  },
})
export default class ThreadMessageAttachment extends Vue {
  @Prop()
  private attachment!: IAttachment

  private isProposalDocumentAgreementModalOpen = false

  private onProposalDocumentAgreementModalOk = () => {}

  mounted() {}

  private openProposalDocumentAgreementModal() {
    this.isProposalDocumentAgreementModalOpen = true
  }

  private closeProposalDocumentAgreementModal() {
    this.isProposalDocumentAgreementModalOpen = false
  }

  private async openProposalDocument(attachment: IAttachment) {
    if (!this.proposal) {
      console.error('proposal is not found')
      return
    }
    if (!this.proposal.isAgreedProposalDocumentAgreement) {
      this.onProposalDocumentAgreementModalOk = () => {
        this.openProposalDocument(attachment)
      }
      this.openProposalDocumentAgreementModal()
      return
    }

    const res = await this.$services.message.downloadAttachmentByUrl(
      attachment.threadDetailAttachment as string
    )
    const blob = await res.data
    const newBlob = new Blob([blob], { type: 'application/pdf' })
    const objUrl = window.URL.createObjectURL(newBlob)
    const objectLink = document.createElement('a')
    objectLink.href = objUrl
    objectLink.target = '_blank'
    objectLink.click()
  }

  get proposal() {
    return CurrentConnectProposalStore.getCurrentConnectProposal
  }
}
