import { Context } from '@nuxt/types'
import { LockStore } from '~/utils/store-accessor'
import {
  ChangePasswordPayload,
  IConnectCompanySettingAccount,
} from '~/Interfaces/connectCompany/IConnectCompanySettingAccount'

export default class ConnectCompanySettingAccountService {
  private readonly context: Context

  constructor(context: Context) {
    this.context = context
  }

  async getConnectCompanySettingAccount(): Promise<
    IConnectCompanySettingAccount | undefined
  > {
    LockStore.lock(LockStore.types.company)
    try {
      const response = await this.context.$axios.get(
        'api/company/setting/account'
      )

      return JSON.parse(JSON.stringify(response.data))
    } catch (err) {
      console.error(err)
    } finally {
      LockStore.unlock(LockStore.types.company)
    }
  }

  async changePassword(payload: ChangePasswordPayload): Promise<void> {
    LockStore.lock(LockStore.types.company)
    try {
      const response = await this.context.$axios.put(
        'api/company/setting/account/password',
        payload
      )

      return JSON.parse(JSON.stringify(response.data))
    } catch (err) {
      console.error(err)
    } finally {
      LockStore.unlock(LockStore.types.company)
    }
  }
}
