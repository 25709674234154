


















































import { Component, Emit, Prop, Vue } from 'nuxt-property-decorator'
import IAttachment from '~/Interfaces/Message/IAttachment'

@Component
export default class ConnectAttachmentThumbnail extends Vue {
  private showingTooltip = false

  @Prop()
  private attachment!: IAttachment

  @Prop({ default: false })
  private noHover!: boolean

  @Prop({ default: null })
  private onClick!: (attachment: IAttachment) => void

  private thumbnailBinary: string = ''

  async mounted() {
    this.thumbnailBinary = await this.getThumbnailImageBinary()
  }

  private onClickLink() {
    if (this.onClick) {
      this.onClick(this.attachment)
    } else {
      window.open(this.attachment.threadDetailAttachment as string)
    }
  }

  get fileName(): string {
    if (!this.attachment.threadDetailAttachmentFileName) return ''

    // 拡張子は除く
    return this.attachment.threadDetailAttachmentFileName.replace(
      /\.[^/.]+$/,
      ''
    )
  }

  get fileSize(): string {
    if (!this.attachment.threadDetailAttachmentFileSize) return '0B'

    // 1MB以上ならMB表示
    if (this.attachment.threadDetailAttachmentFileSize > 1024 * 1024) {
      return `${(
        this.attachment.threadDetailAttachmentFileSize /
        1024 /
        1024
      ).toFixed(2)}MB`
    }

    // 1KB以上ならKB表示
    if (this.attachment.threadDetailAttachmentFileSize > 1024) {
      return `${(this.attachment.threadDetailAttachmentFileSize / 1024).toFixed(
        2
      )}KB`
    }

    // 1KB未満ならB表示
    return `${this.attachment.threadDetailAttachmentFileSize}B`
  }

  private async getThumbnailImageBinary(): Promise<string> {
    const res = await this.$services.message.downloadAttachmentThumbnailByUrl(
      this.attachment.threadDetailAttachmentThumbnail as string
    )
    const blob = (await res.data) as Blob
    const newBlob = new Blob([blob], { type: 'image/jpg' })

    return await this.blobToBase64(newBlob)
  }

  private blobToBase64(blob: Blob): Promise<string> {
    return new Promise((resolve, reject) => {
      const reader = new FileReader()
      reader.onload = () => resolve(reader.result as string)
      reader.onerror = (err) => reject(err)
      reader.readAsDataURL(blob)
    })
  }

  private showTooltip() {
    this.showingTooltip = true
  }

  private hideTooltip() {
    this.showingTooltip = false
  }
}
