import { Context } from '@nuxt/types'
import { LockStore } from '~/utils/store-accessor'
import { IConnectCompanySettingUser } from '~/Interfaces/connectCompany/IConnectCompanySettingUser'

export default class ConnectCompanySettingUserService {
  private readonly context: Context

  constructor(context: Context) {
    this.context = context
  }

  async getConnectCompanySettingUsers(): Promise<
    IConnectCompanySettingUser[] | undefined
  > {
    LockStore.lock(LockStore.types.company)
    try {
      const response = await this.context.$axios.get('api/company/setting/user')

      return JSON.parse(JSON.stringify(response.data))
    } catch (err) {
      console.error(err)
    } finally {
      LockStore.unlock(LockStore.types.company)
    }
  }
}
