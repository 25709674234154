import { Auth } from '@nuxtjs/auth-next'

export default class UserService {
  private readonly auth: Auth
  private userPolicies: string[]

  constructor(auth: Auth) {
    this.auth = auth
    this.userPolicies = this.auth.user?.policies as string[]
  }

  public async refreshUserPolicies(): Promise<void> {
    await this.auth.fetchUser()
    this.userPolicies = this.auth.user?.policies as string[]
  }

  /**
   * チームイメージを取得する
   */
  public get getTeamImage(): string | undefined {
    return <string | undefined>this.auth.user?.team_image
  }

  /**
   * イメージを取得する
   */
  public get getConnectCompanyImage(): string | undefined {
    const connectCompany = this.auth.user?.connectCompany as any

    return <string | undefined>connectCompany?.upload_image_url
  }

  public getConnectClientHashId(): string | undefined {
    return <string | undefined>this.auth.user?.connect_client_hash_id
  }

  public getConnectClientAccountHashId(): string | undefined {
    return <string | undefined>this.auth.user?.connect_client_account_hash_id
  }

  public getConnectClientAccountRole(): string | undefined {
    return <string | undefined>this.auth.user?.role
  }

  public getConnectClientAccountRollIsAdmin(): boolean {
    return this.auth.user?.role === 'admin'
  }

  public isClient(): boolean {
    if (!this.auth.user) {
      return false
    }

    return this.auth.user.connect_client_hash_id !== undefined
  }

  /**
   * テストアカウントかどうか
   */
  public get isTestAccount(): boolean | undefined {
    if (this.isClient()) {
      return this.auth.user?.is_test_account as boolean | undefined
    }

    return this.auth.user?.is_test_account as boolean | undefined
  }

  public get isGuestAccount(): boolean {
    return this.auth.user?.role === 'guest'
  }

  public get isRequestLimited(): boolean {
    return !!this.auth.user?.is_request_limited
  }

  public get isSupportClient(): boolean {
    return !!this.auth.user?.is_support_account
  }

  public get isSupportPartner(): boolean {
    // eslint-disable-next-line camelcase
    return !!this.auth.user?.is_partner_support_account
  }

  public get isAgreedServiceAgreement(): boolean {
    // eslint-disable-next-line camelcase
    return !!this.auth.user?.is_agreed_service_agreement
  }

  public get shouldRegisterAddress(): boolean {
    if (!this.auth.user) {
      return true
    }

    if (!this.auth.user.connect_client_address) {
      return true
    }

    return !this.auth.user.connect_client_address
  }

  public get promptInvoiceRegistration(): boolean {
    if (!this.auth.user) {
      return false
    }

    if (this.isClient()) {
      return false
    }

    const connectCompany = this.auth.user?.connectCompany as any
    // eslint-disable-next-line camelcase
    return !connectCompany?.invoice_number
  }

  public get canScout(): boolean {
    // eslint-disable-next-line camelcase
    return !!this.auth.user?.company_can_scout
  }

  public get abTestPatternName(): 'a' | 'b' {
    // eslint-disable-next-line camelcase
    const abTestPatternName = this.auth.user?.abtest_pattern_name
    if (abTestPatternName === 'b') {
      return 'b'
    }

    return 'a'
  }

  // 権限セットの権限を取得
  public hasProposalReadPolicy(): boolean {
    return this.userPolicies.includes('proposal_read')
  }

  public hasProposalWritePolicy(): boolean {
    return this.userPolicies.includes('proposal_write')
  }

  public hasProjectQualityManagerPolicy(): boolean {
    return this.userPolicies.includes('project_quality_manager')
  }

  public hasProjectDirectorPolicy(): boolean {
    return this.userPolicies.includes('project_director')
  }
}
