import { Context } from '@nuxt/types'
import { LockStore } from '~/utils/store-accessor'
import { ConnectCompanyInfoPayload, IConnectCompany } from '~/Interfaces'
import {
  UpdateConnectCompanySettingCompanyPayload,
  IConnectCompanySettingCompany,
} from '~/Interfaces/connectCompany/IConnectCompanySettingCompany'

export default class ConnectCompanySettingCompanyService {
  private readonly context: Context

  constructor(context: Context) {
    this.context = context
  }

  async getConnectCompanySettingCompany(): Promise<
    IConnectCompanySettingCompany | undefined
  > {
    LockStore.lock(LockStore.types.company)
    try {
      const response = await this.context.$axios.get(
        'api/company/setting/company'
      )

      return JSON.parse(JSON.stringify(response.data))
    } catch (err) {
      console.error(err)
    } finally {
      LockStore.unlock(LockStore.types.company)
    }
  }

  async putConnectCompanySettingCompany(
    payload: UpdateConnectCompanySettingCompanyPayload
  ): Promise<void> {
    LockStore.lock(LockStore.types.company)
    try {
      await this.context.$axios.put('api/company/setting/company', payload)
    } catch (err) {
      console.error(err)
    } finally {
      LockStore.unlock(LockStore.types.company)
    }
  }
}
