// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".invoiceForm_10kqN{max-width:960px}.formField_3XLKF{margin-bottom:20px}.formField_3XLKF .notice_34v-B{font-size:13px;font-weight:400;color:#222;color:var(--color-text-normal-default,#222);margin-bottom:8px}.label_3WEV5{display:flex;justify-content:space-between;align-items:center}.label_3WEV5 .private_2bQtk{display:inline-flex;justify-content:center;align-items:center;background:#eaebeb;color:#707070;font-size:12px;font-weight:600;width:65px;height:22px;grid-gap:0 5px;gap:0 5px}.attention_1nvcH .detail_3lvXT{width:100%;font-size:13px;font-weight:400;line-height:19.5px;color:#222;color:var(--color-text-normal-default,#222)}.attention_1nvcH .detail_3lvXT .notice_34v-B{font-size:13px;font-weight:400;color:#7c7c7c;color:var(--Color-Text-Weak,#7c7c7c)}", ""]);
// Exports
exports.locals = {
	"invoiceForm": "invoiceForm_10kqN",
	"formField": "formField_3XLKF",
	"notice": "notice_34v-B",
	"label": "label_3WEV5",
	"private": "private_2bQtk",
	"attention": "attention_1nvcH",
	"detail": "detail_3lvXT"
};
module.exports = exports;
